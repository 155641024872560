import { useDispatch, useSelector } from "react-redux";
import { useEffect, Fragment } from "react";
import { fetchData as fetchImpressions } from "../store/public-impressions";
import { AppDispatch, RootState } from "../store";

import { GoogleMap, LoadScript, Marker, Polyline} from "@react-google-maps/api";
import { DeviceLocation, Device, Impression } from "../types";

import config from "../config";


let ImpressionPinPack = (params: { impressions: Impression[], device?: Device }) => {
  let locations: DeviceLocation[] = params.impressions.flatMap((impression) => {
    try {
      return JSON.parse(impression.locationJson)
    } catch {
      return []
    }
  })

  const polylineOptions = {
    geodesic: true,
    strokeColor: "#0000FF", // Blue
    strokeOpacity: 0.8,
    strokeWeight:1,
  };

  if (locations.length < 1) {
    return <Fragment />
  }
  const image =
  "/marker.svg";
return (
  <Fragment>
  <Polyline path={locations.map(l => ({ lat: l.lat, lng: l.lng }))} options={polylineOptions} />
  {params.impressions[0] && (
    <Marker
      position={{ lat: locations[0].lat, lng: locations[0].lng }}
      label={params.device?.title}
      icon={{
        url: image,
      }}
    />
  )}
</Fragment>
)
}

let TheMap = () => {
  let dispatch = useDispatch<AppDispatch>()
  let impressionsStore = useSelector(
    (state: RootState) => state.publicImpressions
  )

  useEffect(() => {
    dispatch(fetchImpressions({}));
  }, [dispatch]);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  }

  return (
    <LoadScript googleMapsApiKey={config.googleMapsKey}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{ lat: 55.753215, lng: 37.622504 }}
        zoom={10}
      >

        <ImpressionPinPack impressions={impressionsStore.data} />
      </GoogleMap>
    </LoadScript>
  );
};

let MapImpressionsPage = () => {
  return (
    <div className="map-container">
      <TheMap />
    </div>
  )
}

export default MapImpressionsPage
